<template>
  <div>
    <div class="h-full">
      <div class="rounded bg-white h-full" >
        <div class="bg-white cust_card p-2 heading-3 text-text1">
            <p>Extreme Dog Fence</p>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
},
  data () {
    return {
    }
  },
  created() {
  },
  beforeMount () {
  },
  mounted () {
    document.title = 'Consumer Sales ◾ Ext. Dog Fence'
  },
  watch: {
  },
  methods: {
  },
  beforeDestroy () {
  }
}
</script>
<style scoped>
</style>